/* React Tinongo <Testimonial> component */

import React from 'react';
import Img from 'gatsby-image';

import Section from './section';

import styles from './testimonial.module.scss';

/* The <Testimonial> component */
function Testimonial(props) {
  const results = props.erfolge.map((erfolg, idx) => (
    <li key={idx}>{erfolg}</li>
  ));

  return (
    <Section title={`${props.name} – ${props.sportart}`} withDivider>
      <div className={`bodyText ${styles.testimonial}`}>
        <Img
          className={styles.testimonialImage}
          key={props.image.id}
          fluid={props.image.fluid}
          alt={props.image.titel}
          style={{
            backgroundColor: '#000',
          }}
          objectFit="contain"
          objectPosition="50% 50%"
        />

        <div className={styles.testimonialContent}>
          <blockquote className={styles.testimonialQuote}>
            <p>{props.zitat.zitat}</p>
          </blockquote>
          <div className={styles.testimonialResults}>
            <strong>Erfolge</strong>
            <ul>{results}</ul>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Testimonial;
