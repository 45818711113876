//
// Gatsby Tinongo Testimonials page
// Route: /botschafter
//

import React from 'react';
import { graphql } from 'gatsby';

import AppShell from '../components/app-shell';
import Content from '../components/content';
import SEO from '../components/seo';
import Testimonial from '../components/testimonial';

export default function Testimonials({ data }) {
  const header = (
    <header>
      <h1 className="pageTitle">Tinongo Botschafter</h1>
    </header>
  );

  const testimonials = data.allContentfulTestimonials.nodes.map(
    (testimonial) => <Testimonial key={testimonial.id} {...testimonial} />
  );

  return (
    <>
      <SEO title="Tinongo Botschafter" />
      <AppShell>
        <Content>
          <article>
            {header}
            {testimonials}
          </article>
        </Content>
      </AppShell>
    </>
  );
}

export const query = graphql`
  query {
    allContentfulTestimonials {
      nodes {
        id
        name
        sportart
        erfolge
        zitat {
          zitat
        }
        image {
          id
          title
          fluid(maxWidth: 550, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
